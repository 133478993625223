import { css, styled } from '@mui/system'
import { DotOverlay } from 'components'
import { ParallaxBanner } from 'react-scroll-parallax'

interface Props {
  backgroundSrc?: string
  backgroundCompontent?: JSX.Element
  foregroundComponent?: JSX.Element
  height?: number
  children?: JSX.Element
}

const Wrapper = styled('div')<{ height: number }>(
  ({ height }) => css`
    position: relative;
    height: ${height}px;
    overflow: hidden;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2) inset,
      0 -1rem 1rem rgba(0, 0, 0, 0.2) inset;
    pointer-events: none;
  `
)

export const Parallax = (props: Props) => {
  const {
    backgroundSrc,
    height = 300,
    children,
    foregroundComponent,
    backgroundCompontent
  } = props

  // TODO explore here further... https://react-scroll-parallax.damnthat.tv/docs/examples/banners

  return (
    <ParallaxBanner
      layers={[
        {
          speed: -20,
          image: backgroundSrc // todo SET BLUR HERE...
        },
        {
          speed: -20,
          children: <DotOverlay />
        },
        {
          speed: -20,
          children: backgroundCompontent
        },

        {
          speed: -15,
          children: foregroundComponent
        }
      ]}
    >
      <Wrapper height={height}>{children}</Wrapper>
    </ParallaxBanner>
  )
}
