import { css, styled } from '@mui/system'

interface Props {
  children: JSX.Element
  color: string
}

const Wrapper = styled('div')`
  position: relative;
  // margin: 0 -4rem;
  overflow: scroll;
`

const LeftOverlay = styled('div')<{ color: string }>(
  ({ color }) => css`
    background: rgb(${color});

    position: absolute;
    width: calc(5rem - 10px);
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(${color}, 0.8);
    backdrop-filter: saturate(180%) blur(20px);

    :after {
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      left: 0;
      top: 0;

      background-image: linear-gradient(to right, rgba(${color}), transparent);
    }
  `
)
const RightOverlay = styled('div')<{ color: string }>(
  ({ color }) => css`
    background: rgb(${color});

    position: absolute;
    width: calc(5rem - 10px);
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(${color}, 0.8);
    backdrop-filter: saturate(180%) blur(20px);

    :after {
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      right: 0;
      top: 0;

      background-image: linear-gradient(to right, transparent, rgba(${color}));
    }
  `
)

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding: 0 1rem 2rem 0;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`

export const SwipeList = (props: Props) => {
  const { children, color } = props

  return (
    <Wrapper>
      {/* <LeftOverlay color={color} /> */}
      <Container>{children}</Container>
      {/* <RightOverlay color={color} /> */}
    </Wrapper>
  )
}
