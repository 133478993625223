import { Tab, Tabs } from '@mui/material'
import { styled } from '@mui/system'
import { DataContext } from 'contexts'
import { Category, Item } from 'forms'
import { useViewportSize } from 'hooks'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Category as CategoryProps, ItemProps } from 'types'

const Wrapper = styled('div')``

const ContentWrapper = styled('div')`
  margin-top: 2rem;
`

export const Edit = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const { categories, writeCategory, writeProduct } = useContext(DataContext)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isXS, isS } = useViewportSize()

  const handleCreateCategory = (data: CategoryProps) => {
    writeCategory(data)
    navigate('/')
  }

  const handleCreateItem = async (data: ItemProps) => {
    const { file, title, description, category, price, link } = data

    fetch(file).then(async res => {
      const file = await res.blob()

      writeProduct(file, title, description, price, category, link, () =>
        navigate('/')
      )
    })
  }

  return (
    <Wrapper>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='basic tabs example'
      >
        <Tab label='New Item' />
        <Tab label='New Category' />
      </Tabs>

      <ContentWrapper>
        {value === 0 && (
          <Item
            onSubmit={handleCreateItem}
            error=''
            onClearError={() => {}}
            categories={categories || []}
          />
        )}
        {value === 1 && (
          <Category
            onSubmit={handleCreateCategory}
            error=''
            onClearError={() => {}}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  )
}
