import MenuIcon from '@mui/icons-material/Menu'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover
} from '@mui/material'
import { css, styled } from '@mui/system'
import { DataContext } from 'contexts'
import { getAuth } from 'firebase/auth'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { hyphenateString } from 'utils'

const auth = getAuth() //?

const Wrapper = styled('div')`
  display: flex;
`

const StyledButton = styled('button')`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: #333;
`

const StyledPopover = styled(Popover)(
  ({
    theme: {
      shape: { borderRadius }
    }
  }) => css`
    .MuiPaper-root {
      // border-radius: ${borderRadius}rem;
    }
  `
)

export const Menu = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { categories } = useContext(DataContext)

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(
    null
  )

  const handleClick = (event: any) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <StyledButton onClick={handleClick}>
        <MenuIcon />
      </StyledButton>
      <Wrapper>
        {anchorElement && (
          <StyledPopover
            open={Boolean(anchorElement)}
            anchorEl={anchorElement}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <Box>
              <List>
                {categories &&
                  categories.map(category => (
                    <ListItem
                      key={`menu-item-${hyphenateString(category.title)}`}
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => {
                          document
                            .getElementById(
                              `slice-${hyphenateString(category.title)}`
                            )
                            ?.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                              inline: 'nearest'
                            })

                          handleClose()
                        }}
                      >
                        <ListItemText primary={category.title} />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
              <Divider />
              {/* <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate('/about')
                    handleClose()
                  }}
                >
                  <ListItemText primary='About' />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate('/contact')
                    handleClose()
                  }}
                >
                  <ListItemText primary='Contact' />
                </ListItemButton>
              </ListItem>
            </Box>
          </StyledPopover>
        )}
      </Wrapper>
    </>
  )
}
