import { styled } from '@mui/system'
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid'
import { AnimatedTitle } from 'components/AnimatedTitle'
import { useNavigate } from 'react-router-dom'
import { Message } from 'types'

interface Props {
  messages: Message[] | null
}

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: 130 },
  { field: 'email', headerName: 'Email', width: 130 },
  { field: 'message', headerName: 'Message', width: 1000 }
]

const MessagesWrapper = styled('div')`
  margin-top: 2rem;
`

export const Messages = (props: Props) => {
  const { messages } = props

  const navigate = useNavigate()

  const handleEvent: GridEventListener<'rowClick'> = (params, event) => {
    event.preventDefault()
    event.stopPropagation()
    // TODO explore here...

    navigate(`/profile/message/${params.id}`)

    return false
  }

  return (
    <div>
      <AnimatedTitle title='Messages' />
      <MessagesWrapper>
        {messages && (
          <DataGrid
            onRowClick={handleEvent}
            rows={messages}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 }
              }
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        )}
      </MessagesWrapper>
    </div>
  )
}
