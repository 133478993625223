import { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { dayNumberFormatter } from 'utils'
import moment from 'moment'

window.moment = moment

interface Props {
  onChange: (date: number) => void
  date: number
}

const Wrapper = styled('div')`
  display: flex;
  align-items: end;
`

const yearsTotal = moment().year()
const days = Array.from({ length: 31 }, (_, i) => dayNumberFormatter(i + 1))
const months = moment.monthsShort()
const years = Array.from({ length: 100 }, (_, i) => yearsTotal - i)

export const DateOfBirth = (props: Props) => {
  const { onChange, date } = props

  // NOTE: day/month are indexes in the above arrays (hence -1)...
  const [dayIndex, setDay] = useState<number>(date ? moment(date).day() : -1)
  const [monthIndex, setMonth] = useState<number>(
    date ? moment(date).month() : -1
  )
  const [yearIndex, setYear] = useState<number>(
    date ? years.indexOf(moment(date).year()) : -1
  )

  const { t } = useTranslation()

  useEffect(() => {
    if (dayIndex > -1 && monthIndex > -1 && yearIndex > -1) {
      const day = dayIndex + 1
      const month = monthIndex + 1
      const year = years[yearIndex]

      const dateTime = moment(
        `${dayNumberFormatter(day)}/${dayNumberFormatter(month)}/${year}`,
        'DD/MM/YYYY'
      ).valueOf()

      onChange(dateTime)
    }
  }, [dayIndex, monthIndex, yearIndex, onChange])

  const handleDayChange = (event: SelectChangeEvent<typeof dayIndex>) => {
    const {
      target: { value }
    } = event

    setDay(value as number)
  }

  const handleMonthChange = (event: SelectChangeEvent<typeof monthIndex>) => {
    const {
      target: { value }
    } = event

    setMonth(value as number)
  }

  const handleYearChange = (event: SelectChangeEvent<typeof yearIndex>) => {
    const {
      target: { value }
    } = event

    setYear(value as number)
  }

  return (
    <Wrapper>
      <FormControl fullWidth>
        <InputLabel id='select-day' variant='standard'>
          {t('shared.day')}
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={dayIndex > -1 ? dayIndex : ''}
          label='Day'
          onChange={handleDayChange}
          variant='standard'
        >
          {days.map((day, i) => (
            <MenuItem key={`day-${i}`} value={i}>
              {day}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id='select-month' variant='standard'>
          {t('shared.month')}
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={monthIndex > -1 ? monthIndex : ''}
          label='Month'
          onChange={handleMonthChange}
          variant='standard'
        >
          {months.map((month, i) => (
            <MenuItem key={`month-${i}`} value={i}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id='select-year' variant='standard'>
          {t('shared.year')}
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={yearIndex > -1 ? yearIndex : ''}
          label='Year'
          onChange={handleYearChange}
          variant='standard'
        >
          {years.map((year, i) => (
            <MenuItem key={`year-${i}`} value={i}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Wrapper>
  )
}
