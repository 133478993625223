import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next'

const CopyrightWrapper = styled('div')`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
  opacity: 0.2;
`

export const Copyright = () => {
  const { t } = useTranslation()

  return <CopyrightWrapper>{t('copyright')}</CopyrightWrapper>
}
