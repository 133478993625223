import { PrivateRoute } from 'components'
import { Account } from 'containers'
import { Route, Routes } from 'react-router-dom'
import {
  About,
  Contact,
  Edit,
  ForgotPassword,
  Home,
  Login,
  Message,
  NotFound,
  Product,
  Profile,
  Register,
  ResetPassword
} from 'views'

export const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />}>
        <Route path='product/:productId' element={<Product />} />
        <Route path='about' element={<About />} />
        <Route path='contact' element={<Contact />} />
        <Route path='edit' element={<Edit />} />
      </Route>
      <Route path='/login' element={<Login />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/register' element={<Register />} />
      <Route
        path='/'
        element={
          <PrivateRoute path='/'>
            <Account />
          </PrivateRoute>
        }
      >
        <Route path='profile' element={<Profile />}>
          <Route path='message/:messageId' element={<Message />} />
        </Route>
      </Route>
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
