import { useState, useEffect, ReactNode } from 'react'
import { styled, css } from '@mui/system'
import { fadeInAnimation, fadeOutAnimation } from 'utils'

interface Props {
  content: Content[]
  fadeTime?: number
  hangTime?: number
}

interface Content {
  content: ReactNode
  duration?: number
  delay?: number
}

const Wrapper = styled('div')<{ isFadeIn: boolean; fadeTime: number }>(
  ({ isFadeIn, fadeTime }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: ${isFadeIn ? fadeInAnimation : fadeOutAnimation};
    animation-duration: ${fadeTime}ms;
    animation-fill-mode: forwards;
    white-space: pre-line;
    font-size: 3rem;
  `
)

export const ContentFader = (props: Props) => {
  const { content, fadeTime = 2000, hangTime = 6000 } = props

  const [isFirstRender, setIsFirstRender] = useState(true)
  const [contentIndex, setContentIndex] = useState(0)
  const [isFadeIn, setIsFadeIn] = useState(true)

  useEffect(() => {
    setContentIndex(0)
  }, [content])

  useEffect(() => {
    let fadeInTimeout: NodeJS.Timeout
    let fadeOutTimeout: NodeJS.Timeout
    let nextAnimationTimeout: NodeJS.Timeout

    const nextAnimation = () => {
      setIsFadeIn(true)

      fadeInTimeout = setTimeout(() => {
        setIsFadeIn(false)
      }, (content[contentIndex].duration || hangTime) - fadeTime)

      fadeOutTimeout = setTimeout(() => {
        const newContentIndex =
          contentIndex + 1 > content.length - 1 ? 0 : contentIndex + 1

        setContentIndex(newContentIndex)

        nextAnimationTimeout = setTimeout(
          nextAnimation,
          content[newContentIndex].delay
        )
      }, content[contentIndex].duration)
    }

    if (isFirstRender) {
      content.length > 1 && nextAnimation()
    } else {
      nextAnimationTimeout = setTimeout(() => {
        nextAnimation()
        setIsFirstRender(false)
      }, content[contentIndex].delay)
    }

    return () => {
      clearTimeout(fadeInTimeout)
      clearTimeout(fadeOutTimeout)
      clearTimeout(nextAnimationTimeout)
    }
  }, [contentIndex, content, isFirstRender, hangTime, fadeTime])

  return (
    <Wrapper isFadeIn={isFadeIn} fadeTime={fadeTime}>
      {content[contentIndex] && content[contentIndex].content}
    </Wrapper>
  )
}
