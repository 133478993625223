import { yupResolver } from '@hookform/resolvers/yup'
import { TextareaAutosize } from '@mui/base'
import { Button, FormLabel, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import { AnimatedTitle, ErrorMessage } from 'components'
import { DataContext, SnackbarContext } from 'contexts'
import { useContext, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ContactProps } from 'types'
import * as yup from 'yup'

interface Props {
  error: string | null
  onClearError: () => void
}

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  overflow: scroll;
`

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledTextField = styled(TextField)`
  width: 100%;
`

const MessageWrapper = styled('div')`
  margin-top: 1rem;
`

const FormWrapper = styled('div')`
  margin-top: 2rem;
`

const StyledTextArea = styled(TextareaAutosize)`
  resize: none;
  border: none;
  outline: none;
  margin-top: 0.5rem;
  text-transform: uppercase;
  width: 100%;
  font-size: 1rem;

  ::placeholder {
    text-transform: initial;
  }
`

const schema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    email: yup.string().email().required('Required'),
    message: yup.string().required('Required')
  })
  .required()

export const Contact = (props: Props) => {
  const { error, onClearError } = props

  const [robotId, setRobotId] = useState<string | null>(null)

  const muiTheme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { onEnqueueSnackbar } = useContext(SnackbarContext)

  const { handleSubmit, control } = useForm<ContactProps>({
    resolver: yupResolver(schema)
  })

  const handleFormSubmit = (data: ContactProps) => {
    const { name, email, message } = data

    onClearError()

    if (robotId) {
      writeMessage(name, email, message, () => navigate('/'))
    } else {
      onEnqueueSnackbar(t('contact.error'), 'error')
    }
  }

  const { writeMessage } = useContext(DataContext)

  const onChange = (value: string) => setRobotId(value)

  return (
    <StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
      <div>
        <AnimatedTitle title={t('contact.title')} />

        {error && <ErrorMessage theme={muiTheme}>{error}</ErrorMessage>}

        <FormWrapper>
          <Typography>{t('contact.copy')}</Typography>
          <Controller
            name='name'
            defaultValue=''
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledTextField
                onChange={onChange}
                value={value}
                label={t('contact.name')}
                error={!!error}
                helperText={error && error.message}
                required
                variant='standard'
              />
            )}
          />
          <Controller
            name='email'
            defaultValue=''
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledTextField
                onChange={onChange}
                value={value}
                label={t('contact.email')}
                error={!!error}
                helperText={error && error.message}
                required
                variant='standard'
              />
            )}
          />
          <Controller
            name='message'
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <MessageWrapper>
                <FormLabel>{`${t('contact.message')} *`}</FormLabel>
                <StyledTextArea
                  placeholder={t('contact.messagePlaceholder')}
                  value={value}
                  onChange={onChange}
                  minRows={10}
                />
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
              </MessageWrapper>
            )}
          />
        </FormWrapper>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_ID}
          onChange={onChange}
        />
      </div>
      <ButtonWrapper>
        <Button type='submit' variant='outlined' fullWidth>
          {t('shared.send')}
        </Button>
      </ButtonWrapper>
    </StyledForm>
  )
}
