import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography
} from '@mui/material'
import { styled } from '@mui/system'
import {
  AnimatedTitle,
  Avatar,
  Dialog,
  Drawer,
  HTMLTitle,
  MarginWrapper,
  Messages
} from 'components'
import { AuthContext, DataContext } from 'contexts'
import { ChangeEmail, ChangeName, ChangePassword } from 'forms'
import { useCurrentPath } from 'hooks'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
`

const DetailsWrapper = styled('div')`
  margin-top: 2rem;
  width: 100%;
  text-align: center;
`

const AccordionWrapper = styled('div')`
  margin: 2rem 0;
`

const Slice = styled('div')`
  padding-top: 3rem;
  padding-bottom: 3rem;
`

export const Profile = () => {
  const [isDeleteAccountVisible, setIsDeleteAccountVisible] = useState(false)

  const navigate = useNavigate()

  const {
    deleteUser,
    updatePassword,
    updateEmail: updateAuthEmail
  } = useContext(AuthContext)
  const {
    user,
    messages,
    writeAvatar,
    updateName,
    updateEmail,
    fetchMessages
  } = useContext(DataContext)

  const { t } = useTranslation()
  const currentPath = useCurrentPath()

  const handleUpdateAvatar = async (
    imageUrl: string,
    callback?: () => void
  ) => {
    fetch(imageUrl).then(async res => {
      const file = await res.blob()

      writeAvatar(file, callback)
    })
  }

  const handleDeleteAccount = () => deleteUser()
  const handleChangeEmail = (email: string, password: string) => {
    updateAuthEmail(email, password)
    updateEmail(email)
  }
  const handleChangePassword = (oldPassword: string, newPassword: string) =>
    updatePassword(oldPassword, newPassword)

  const handleChangeName = (forename: string, surname: string) => {
    updateName(forename, surname)
  }

  useEffect(() => {
    fetchMessages()
  }, [fetchMessages])

  const isProfile = currentPath === '/profile'

  return (
    <>
      <HTMLTitle tag='account.tabTitle' />
      <MarginWrapper>
        <Wrapper>
          {isDeleteAccountVisible && (
            <Dialog
              title={t('account.deleteAccountTitle')}
              copy={t('account.deleteAccountSubtitle')}
              onSubmit={handleDeleteAccount}
              onCancel={() => setIsDeleteAccountVisible(false)}
            />
          )}
          <Avatar onUpdateAvatar={handleUpdateAvatar} src={user?.avatarSrc} />
          {user && (
            <DetailsWrapper>
              <Typography>{`${user.forename} ${user.surname}`}</Typography>
              <Typography>{`${user.email}`}</Typography>
              <Slice>
                <Messages messages={messages} />
              </Slice>
              <Slice>
                <AnimatedTitle title='Account' />
                <AccordionWrapper>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='change name'
                    >
                      <Typography>{t('account.changeName')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ChangeName
                        forename={user.forename}
                        surname={user.surname}
                        onSubmit={handleChangeName}
                        error=''
                        onClearError={() => {}}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='change email'
                    >
                      <Typography>{t('account.changeEmail')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ChangeEmail
                        onSubmit={handleChangeEmail}
                        error=''
                        onClearError={() => {}}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='change password'
                    >
                      <Typography>{t('account.changePassword')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ChangePassword
                        onSubmit={handleChangePassword}
                        error=''
                        onClearError={() => {}}
                      />
                    </AccordionDetails>
                  </Accordion>
                </AccordionWrapper>
              </Slice>
            </DetailsWrapper>
          )}
          <Button onClick={() => setIsDeleteAccountVisible(true)}>
            {t('account.deleteAccountTitle')}
          </Button>
        </Wrapper>
      </MarginWrapper>
      <Drawer show={!isProfile} onClose={() => navigate(`/profile`)}>
        <Outlet />
      </Drawer>
    </>
  )
}
