import { AnimatedTitle } from 'components'
import { useViewportSize } from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Product as ProductProps } from 'types'

export const About = () => {
  const [product, setProduct] = useState<ProductProps>()

  const { t } = useTranslation()
  const { isXS, isS } = useViewportSize()

  return (
    <div>
      <AnimatedTitle title={t('about.title')} />
    </div>
  )
}
