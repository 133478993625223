import { css, styled } from '@mui/system'
import { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { HTMLImage } from 'types'

const Wrapper = styled('div')``

const ImageWrapper = styled('div')<{ isVisible?: boolean }>(
  ({ isVisible = true }) => css`
    opacity: ${isVisible ? '1' : '0'};

    span {
      display: block !important;
    }

    img {
      display: block;
    }
  `
)

const StyledLazyLoadImage = styled(LazyLoadImage)``

export const LazyImage = (props: HTMLImage) => {
  const { src, alt } = props
  const [isVisible, setIsVisible] = useState(false)

  const handleAfterLoad = () => setIsVisible(true)

  return (
    <Wrapper>
      <ImageWrapper isVisible={isVisible}>
        <StyledLazyLoadImage
          alt={alt}
          src={src}
          effect='opacity'
          afterLoad={handleAfterLoad}
        />
      </ImageWrapper>
    </Wrapper>
  )
}
