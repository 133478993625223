import { useContext, useEffect } from 'react'
import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ForgotPassword as ForgotPasswordForm } from 'forms'
import { AuthContext } from 'contexts'
import { MarginWrapper, AnimatedTitle, HTMLTitle } from 'components'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
`

const StyledTypography = styled(Typography)`
  margin-top: 1rem;
`

export const ForgotPassword = () => {
  const { requestResetPassword, authUser, loginError, clearError } =
    useContext(AuthContext)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleRegisterButton = () => navigate('/', { replace: true })

  useEffect(() => {
    authUser && navigate('/', { replace: true })
  }, [authUser, navigate])

  return (
    <>
      <HTMLTitle tag='forgotPassword.title' />
      <MarginWrapper>
        <Wrapper>
          <div>
            <AnimatedTitle title={t('forgotPassword.title')} />
            <StyledTypography variant='body1'>
              {t('forgotPassword.description')}
            </StyledTypography>
            <ForgotPasswordForm
              onSubmit={requestResetPassword}
              onClearError={clearError}
              error={loginError}
            />
          </div>
          <ButtonWrapper>
            <Button onClick={handleRegisterButton}>{t('shared.login')}</Button>
          </ButtonWrapper>
        </Wrapper>
      </MarginWrapper>
    </>
  )
}
