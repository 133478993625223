import { styled, css } from '@mui/system'
import { ThemeColorType } from 'types'

export const ColorWrapper = styled('div')<{ background: ThemeColorType }>(
  ({
    background,
    theme: {
      palette: { primary, secondary }
    }
  }) => css`
    position: relative;
    background: ${background === 'primary' ? primary.main : secondary.main};
  `
)
