import { styled } from '@mui/system'
import { Contact as ContactForm } from 'forms'

const Wrapper = styled('div')`
  height: 100%;
`

export const Contact = () => {
  return (
    <Wrapper>
      <ContactForm error='' onClearError={() => {}} />
    </Wrapper>
  )
}
