import { yupResolver } from '@hookform/resolvers/yup'
import { TextareaAutosize } from '@mui/base'
import {
  Button,
  FormControl,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import { Avatar, ErrorMessage } from 'components'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Category, ItemProps } from 'types'
import * as yup from 'yup'

interface Props {
  onSubmit: (data: ItemProps) => void
  error: string | null
  onClearError: () => void
  categories: Category[]
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`

const AvatarWrapper = styled('div')`
  align-self: center;
`

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  overflow: scroll;
`

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: end;
  margin-top: 0.5rem;
`

const StyledTextField = styled(TextField)`
  width: 100%;
`

const StyledTextArea = styled(TextareaAutosize)`
  resize: none;
  border: none;
  outline: none;
  margin: 0;
  text-transform: uppercase;
  width: 100%;

  ::placeholder {
    text-transform: initial;
  }
`

const schema = yup
  .object()
  .shape({
    title: yup.string().required('Required'),
    description: yup.string().required('Required'),
    file: yup.string().required('Required'),
    price: yup.string().required('Required'),
    category: yup.string().required('Required'),
    link: yup.string().required('Required')
  })
  .required()

export const Item = (props: Props) => {
  const { onSubmit, error, onClearError, categories } = props

  const muiTheme = useTheme()
  const { t } = useTranslation()

  const { handleSubmit, control } = useForm<ItemProps>({
    resolver: yupResolver(schema)
  })

  const handleFormSubmit = (data: ItemProps) => {
    onClearError()
    onSubmit(data)
  }

  return (
    <StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
      <Wrapper>
        {error && <ErrorMessage theme={muiTheme}>{error}</ErrorMessage>}
        <Controller
          name='file'
          defaultValue=''
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              {error && <ErrorMessage>Image error</ErrorMessage>}
              <AvatarWrapper>
                <Avatar
                  onUpdateAvatar={onChange}
                  isCircleOverlayVisible={false}
                />
              </AvatarWrapper>
            </>
          )}
        />
        <Controller
          name='title'
          defaultValue=''
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <StyledTextField
              onChange={onChange}
              value={value}
              label={t('shared.titlePlaceholder')}
              error={!!error}
              helperText={error && error.message}
              required
              variant='standard'
            />
          )}
        />
        <Controller
          name='description'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <FormLabel>Description</FormLabel>
              <StyledTextArea
                placeholder='Enter a description...'
                value={value}
                onChange={onChange}
                minRows={4}
              />
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </>
          )}
        />
        <Controller
          name='price'
          defaultValue=''
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <StyledTextField
              onChange={onChange}
              value={value}
              label='Price'
              error={!!error}
              helperText={error && error.message}
              required
              variant='standard'
              type='number'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>£</InputAdornment>
                )
              }}
            />
          )}
        />
        <Controller
          name='category'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl variant='standard'>
              <InputLabel id='demo-simple-select-standard-label'>
                Category
              </InputLabel>
              <Select
                labelId='demo-simple-select-standard-label'
                id='demo-simple-select-standard'
                value={value}
                onChange={onChange}
                label='Age'
              >
                {categories.map(category => (
                  <MenuItem value={category.title}>{category.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name='link'
          defaultValue=''
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <StyledTextField
              onChange={onChange}
              value={value}
              label={t('shared.link')}
              error={!!error}
              helperText={error && error.message}
              required
              variant='standard'
            />
          )}
        />
        <ButtonWrapper>
          <Button type='submit' variant='contained'>
            {t('shared.save')}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </StyledForm>
  )
}
