import ShareIcon from '@mui/icons-material/Share'
import { Button } from '@mui/material'
import { Container, styled } from '@mui/system'
import { AnimatedTitle } from 'components'
import { DataContext, SnackbarContext } from 'contexts'
import { useViewportSize } from 'hooks'
import { useContext, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useParams } from 'react-router-dom'
import { Product as ProductProps } from 'types'

const ContainerWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const MediaWrapper = styled('div')`
  margin: 2rem 0;
`

const ContentWrapper = styled('div')``

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Wrapper = styled('div')`
  height: 100%;
`

const Share = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledNumericFormat = styled(NumericFormat)`
  border: none;
  font-size: 2rem;
  font-family: 'Outfit', sans-serif;
  background: transparent;
`

const StyledCopyToClipboard = styled(CopyToClipboard)`
  cursor: pointer;
`

export const Product = () => {
  const [product, setProduct] = useState<ProductProps>()

  const { t } = useTranslation()
  const { isXS, isS } = useViewportSize()
  const { productId } = useParams()

  const { products } = useContext(DataContext)
  const { onEnqueueSnackbar } = useContext(SnackbarContext)

  useEffect(() => {
    const product = products?.find(product => product.id === productId)

    product && setProduct(product)
  }, [productId, products])

  const handleClick = () => {
    product?.link && window.open(product.link, '_blank')
  }

  return (
    <Wrapper>
      {product && (
        <ContainerWrapper>
          <div>
            <AnimatedTitle title={product.title} />

            {/* <SwipeList color={category.color ?? '255,255,255'}>
              <>
                {products &&
                  products.map(
                    product =>
                      product.category === category.title && (
                        <ShowTile
                          key={`show-tile-${category.title}-${product.title}`}
                          title={product.title}
                          img={{ src: product.src }}
                          onClick={() => navigate(`product/${product.id}`)}
                        />
                      )
                  )}
              </>
            </SwipeList> */}

            <MediaWrapper>
              <img src={product.src} alt={product.title} />
            </MediaWrapper>
            <ContentWrapper>{product.description}</ContentWrapper>
          </div>
          <div>
            <ButtonWrapper>
              <div>
                £
                <StyledNumericFormat
                  value={product.price}
                  decimalScale={2}
                  fixedDecimalScale
                  disabled
                />
              </div>
              <StyledCopyToClipboard
                text={`https://woodenmallet.co.uk/product/${product.id}`}
                onCopy={() => onEnqueueSnackbar(t('shared.copied'), 'success')}
              >
                <Share>
                  {t('shared.share')} <ShareIcon />
                </Share>
              </StyledCopyToClipboard>
            </ButtonWrapper>

            <Button
              type='submit'
              variant='outlined'
              fullWidth
              onClick={handleClick}
            >
              {t('shared.buyNow')}
            </Button>
          </div>
        </ContainerWrapper>
      )}
    </Wrapper>
  )
}
