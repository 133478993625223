import { css, styled } from '@mui/system'
import { Menu, WHLogo } from 'components'
import { useEffect, useState } from 'react'

const Wrapper = styled('div')<{ isVisible: boolean }>(
  ({ isVisible }) => css`
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
    background: ${!isVisible ? 'transparent' : '#fff'};
    box-shadow: ${!isVisible ? 'none' : '0 1rem 1rem rgba(0, 0, 0, 0.1)'};
    padding: 0 1rem;
    height: 4rem;
    transition: background 0.2s, box-shadow 0.2s;
  `
)

const LogoWrapper = styled('a')<{ isVisible: boolean }>(
  ({ isVisible }) => css`
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s;
    opacity: ${isVisible ? 1 : 0};
  `
)

export const Header = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false)

  const handleScroll = () => {
    const currentScrollPos = window.scrollY

    if (currentScrollPos > 50 && !isHeaderVisible) setIsHeaderVisible(true)
    if (currentScrollPos < 50 && isHeaderVisible) setIsHeaderVisible(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <Wrapper isVisible={isHeaderVisible}>
      <Menu />
      <LogoWrapper href='/' isVisible={isHeaderVisible}>
        <WHLogo />
      </LogoWrapper>
    </Wrapper>
  )
}
