import { CardActionArea, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { animated, useSpring } from '@react-spring/web'
import { LazyImage } from 'components'
import { ReactNode } from 'react'
import { Image } from 'types'

interface Props {
  onClick?: () => void
  title: string
  subtitle?: string
  renderActions?: () => ReactNode
  img: Partial<Image>
  onCloseCallback?: () => void
}

const Wrapper = styled(animated.div)`
  cursor: pointer;
  width: 300px;
  margin: 10px;
  overflow: hidden;
`

const StyledTypography = styled(Typography)`
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 1rem;
`

const StyledCardActionArea = styled(CardActionArea)`
  box-shadow: 0.2rem 1rem 1rem rgba(0, 0, 0, 0.1);
`

export const ShowTile = (props: Props) => {
  const {
    title,
    onClick,
    img: { src, alt }
  } = props

  const [styles, set] = useSpring(() => ({
    transform: `scale(1)`,
    from: {
      transform: `scale(1)`
    },
    config: { tension: 400, mass: 2, velocity: 5 }
  }))

  const updateHover = (hovering: boolean) => ({
    transform: `scale(${hovering ? 1.02 : 1})`
  })

  return (
    <Wrapper
      style={styles}
      onMouseEnter={() => set(updateHover(true))}
      onMouseLeave={() => set(updateHover(false))}
    >
      <StyledCardActionArea onClick={onClick && onClick}>
        {src && <LazyImage alt={alt} src={src} />}
      </StyledCardActionArea>
      <StyledTypography gutterBottom variant='body1'>
        {title}
      </StyledTypography>
    </Wrapper>
  )
}
