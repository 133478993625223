import { styled } from '@mui/system'
import etsy from 'assets/etsy.svg'

const StyledLink = styled('a')`
  max-width: 12rem;
  display: block;
`

export const EtsyLink = () => {
  return (
    <StyledLink
      href='https://www.etsy.com/uk/shop/WoodenMalletShop'
      target='_blank'
      rel='noreferrer'
    >
      <img src={etsy} alt='etsy' />
    </StyledLink>
  )
}
