import { css, styled } from '@mui/system'
import { animated, useSpring } from '@react-spring/web'
// import { LazyImage } from 'components'

interface Props {
  title: string
  src: string
  onClick: (index: number) => void
  index: number
  isSelected?: boolean
}

const Wrapper = styled(animated.div, {
  shouldForwardProp: prop => prop !== 'isSelected'
})<{ isSelected?: boolean }>(
  ({ isSelected = false }) => css`
    // padding: 1rem;
    cursor: pointer;
    background: #fff;
    // filter: drop-shadow(0 0.5rem 0.5rem rgb(0 0 0 / 0.4));
    border: ${isSelected ? '3px solid #000' : 'initial'};
  `
)

const ImageWrapper = styled('div')(
  ({
    theme: {
      shape: { borderRadius }
    }
  }) => css`
    border-radius: ${borderRadius}rem;
    overflow: hidden;
    position: relative;
  `
)

const TitleWrapper = styled('div')`
  margin-top: 0.5rem;
`

const ImageShadow = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) inset,
    0 -0.5rem 1rem rgba(0, 0, 0, 0.1) inset;
  transform: translateZ(0);
`

const StyledImg = styled('img')`
  width: 100%;
  display: block;
  cursor: pointer;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2) inset,
    0 -1rem 1rem rgba(0, 0, 0, 0.2) inset;
`

export const Tile = (props: Props) => {
  const { title, src, onClick, index, isSelected = false } = props

  const [styles, set] = useSpring(() => ({
    transform: `scale(1)`,
    from: {
      transform: `scale(1)`
    },
    config: { tension: 400, mass: 2, velocity: 5 }
  }))

  const updateHover = (hovering: boolean) => ({
    transform: `scale(${hovering ? 1.01 : 1})`
  })

  return (
    <Wrapper
      style={styles}
      onMouseEnter={() => set(updateHover(true))}
      onMouseLeave={() => set(updateHover(false))}
      onClick={() => onClick(index)}
      isSelected={isSelected}
    >
      <ImageWrapper>
        <StyledImg src={src} />
        <ImageShadow />
      </ImageWrapper>
      <TitleWrapper>{title}</TitleWrapper>
    </Wrapper>
  )
}
