import { Button, Typography } from '@mui/material'
import { Container, styled } from '@mui/system'
import { AnimatedTitle } from 'components'
import { DataContext, SnackbarContext } from 'contexts'
import { useContext, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Message as MessageProps } from 'types'

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const EmailWrapper = styled(Typography)`
  margin: 1rem 0;
  cursor: pointer;
`

export const Message = () => {
  const [message, setMessage] = useState<MessageProps>()

  // const { t } = useTranslation()
  // const { isXS, isS } = useViewportSize()
  const { messageId } = useParams()

  const { messages } = useContext(DataContext)

  const { t } = useTranslation()

  useEffect(() => {
    const product = messages?.find(message => message.id === messageId)

    product && setMessage(product)
  }, [messageId, messages])

  // const { onEnqueueSnackbar } = useContext(SnackbarContext)

  // useEffect(() => {
  //   const product = products?.find(product => product.id === productId)

  //   product && setProduct(product)
  // }, [productId, products])

  const handleClick = () => {
    // @ts-ignore
    window.location = `mailto:${message?.email}`
  }

  const { onEnqueueSnackbar } = useContext(SnackbarContext)

  return (
    <Wrapper>
      <div>
        {message?.name && <AnimatedTitle title={message?.name} />}
        <CopyToClipboard
          text={message?.email}
          onCopy={() => onEnqueueSnackbar(t('shared.copied'), 'success')}
        >
          <EmailWrapper color='secondary'>{message?.email}</EmailWrapper>
        </CopyToClipboard>
        <div>{message?.message}</div>
      </div>

      <Button type='submit' variant='outlined' fullWidth onClick={handleClick}>
        {t('contact.reply')}
      </Button>
    </Wrapper>
  )
}
