import { LoadingContext, SnackbarContext, StorageContext } from 'contexts'
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check'
import {
  Firestore,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Category, Feature, Message, Product, User } from 'types'
import { v4 as uuidv4 } from 'uuid'

interface DataContextProps {
  // User...
  writeUser: (props: User) => void
  fetchUser: (userId: string) => void
  deleteUser: () => Promise<any>
  clearUser: () => void
  writeAvatar: (file: any, callback?: () => void) => void
  updateName: (forename: string, surname: string) => void
  updateEmail: (email: string) => void

  // Products...
  writeProduct: (
    file: any,
    title: string,
    description: string,
    price: string,
    category: string,
    link: string,
    callback?: () => void
  ) => void
  writeCategory: (data: Category) => void
  fetchProducts: () => void
  fetchCategories: () => void

  // Contact
  writeMessage: (
    name: string,
    email: string,
    message: string,
    callback: () => void
  ) => void
  fetchMessages: () => void

  // features
  fetchFeatures: () => void
  features: Feature | null

  user: User | null
  db: Firestore | null
  categories: Category[] | null
  products: Product[] | null
  messages: Message[] | null
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(
    process.env.REACT_APP_RECAPTCHA_SECRET_ID || ''
  )
})

getAnalytics(app)

export const DataContext = createContext<DataContextProps>(
  {} as DataContextProps
)

export const DataProvider = ({ children }) => {
  const [categories, setCategories] = useState<Category[] | null>(null)
  const [products, setProducts] = useState<Product[] | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const [messages, setMessages] = useState<Message[] | null>(null)
  const [features, setFeatures] = useState<Feature | null>(null)

  const { createImage, deleteAsset } = useContext(StorageContext)
  const { onEnqueueSnackbar } = useContext(SnackbarContext)
  const { setIsDataLoading } = useContext(LoadingContext)

  const { t } = useTranslation()

  const fetchFeatures = useCallback(async () => {
    setIsDataLoading(true)

    const querySnapshot = await getDocs(collection(db, 'features'))

    querySnapshot.forEach(doc => {
      setFeatures(doc.data().features as Feature)
    })

    setIsDataLoading(false)
  }, [setIsDataLoading])

  const writeUser = useCallback(
    async (props: User) => {
      const { id, email, forename, surname, tel, dob } = props
      setIsDataLoading(true)

      let user: User = {
        id,
        email,
        forename,
        surname
      }

      if (tel) user = { ...user, tel }
      if (dob) user = { ...user, dob }

      id && (await setDoc(doc(db, 'users', id), user))

      setIsDataLoading(false)
    },
    [setIsDataLoading]
  )

  const fetchUser = useCallback(
    async (userId: string) => {
      if (!user) {
        setIsDataLoading(true)

        const docRef = doc(db, 'users', userId)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
          docSnap && setUser(docSnap.data() as User)
        } else {
          onEnqueueSnackbar(t('error.fetchUser'), 'error')
        }

        setIsDataLoading(false)
      }
    },
    [onEnqueueSnackbar, setIsDataLoading, t, user]
  )

  const fetchMessages = useCallback(async () => {
    if (user && user.adminLevel === 1) {
      setIsDataLoading(true)

      const querySnapshot = await getDocs(collection(db, 'contacts'))

      const messages: Message[] = []

      querySnapshot.forEach(doc => {
        messages.push(doc.data() as Message)
      })

      setMessages(messages)

      setIsDataLoading(false)
    }
  }, [setIsDataLoading, user])

  const fetchProducts = useCallback(async () => {
    setIsDataLoading(true)

    const querySnapshot = await getDocs(collection(db, 'products'))

    const products: Product[] = []

    querySnapshot.forEach(doc => {
      products.push(doc.data() as Product)
    })

    setProducts(products)

    setIsDataLoading(false)
  }, [setIsDataLoading])

  const fetchCategories = useCallback(async () => {
    setIsDataLoading(true)

    const querySnapshot = await getDocs(collection(db, 'categories'))

    const categories: Category[] = []

    querySnapshot.forEach(doc => {
      categories.push(doc.data() as Category)
    })

    setCategories(categories)

    setIsDataLoading(false)
  }, [setIsDataLoading])

  const clearUser = useCallback(async () => {
    setIsDataLoading(true)
    setUser(null)
    onEnqueueSnackbar(t('success.loggedOut'), 'success')
    setIsDataLoading(false)
  }, [onEnqueueSnackbar, t, setIsDataLoading])

  const fetchWardrobe = useCallback(
    async (userId: string) => {
      setIsDataLoading(true)

      const docRef = doc(db, 'wardrobes', userId)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        docSnap && setCategories(docSnap.data() as Category[])
      }

      setIsDataLoading(false)
    },
    [setIsDataLoading]
  )

  const writeAvatar = useCallback(
    async (file: any, callback?: () => void) => {
      if (user) {
        setIsDataLoading(true)
        user.avatarSrc && deleteAsset({ fileName: user?.avatarSrc })

        createImage({
          file,
          callback(img) {
            if (user?.id) {
              const docRef = doc(db, 'users', user?.id)

              updateDoc(docRef, {
                avatarSrc: img.src
              }).then(() => {
                user.id && fetchUser(user.id)
                onEnqueueSnackbar(t('success.avatarUpdated'), 'success')
                callback && callback()
                setIsDataLoading(false)
              })
            }
          }
        })
      }
    },
    [
      onEnqueueSnackbar,
      t,
      fetchUser,
      createImage,
      deleteAsset,
      user,
      setIsDataLoading
    ]
  )

  const deleteUser = useCallback(async () => {
    if (user?.id) {
      setIsDataLoading(true)

      const docRef = collection(db, 'cards')

      // Clear Created Cards
      const createdCards = query(docRef, where('author', '==', user?.id))

      const createdCardsSnapshot = await getDocs(createdCards)

      createdCardsSnapshot.forEach(
        async card => await updateDoc(card.ref, { author: null })
      )

      // TODO remove unopened created cards?...

      // Clear Received Cards
      const receivedCardsQuery = query(
        docRef,
        where('recipient', '==', user?.id)
      )
      const receivedCardsSnapshot = await getDocs(receivedCardsQuery)

      receivedCardsSnapshot.forEach(
        async card => await updateDoc(card.ref, { recipient: null })
      )

      // Delete user
      await deleteDoc(doc(db, 'users', user.id))

      setIsDataLoading(false)
    }
  }, [user, setIsDataLoading])

  const updateName = useCallback(
    async (forename: string, surname: string) => {
      if (user && user?.id) {
        const docRef = doc(db, 'users', user?.id)

        updateDoc(docRef, {
          forename,
          surname
        })
          .then(() => {
            user.id && fetchUser(user.id)
            onEnqueueSnackbar(t('success.nameUpdated'), 'success')
          })
          .catch(() => {
            onEnqueueSnackbar(t('error.unableToUpdateDetails'), 'error')
          })
      }
    },
    [fetchUser, onEnqueueSnackbar, t, user]
  )

  const updateEmail = useCallback(
    async (email: string) => {
      if (user && user?.id) {
        const docRef = doc(db, 'users', user.id)

        updateDoc(docRef, {
          email
        })
          .then(() => {
            user.id && fetchUser(user.id)
            onEnqueueSnackbar(t('success.emailUpdated'), 'success')
          })
          .catch(() => {
            onEnqueueSnackbar(t('error.unableToUpdateDetails'), 'error')
          })
      }
    },
    [fetchUser, user, onEnqueueSnackbar, t]
  )

  const writeCategory = useCallback(
    async (data: Category) => {
      const { title, description, color } = data

      if (user && user?.id) {
        const id = uuidv4()
        const docRef = doc(db, 'categories', id)

        setDoc(docRef, {
          title,
          description,
          color: color && color.replace('rgb(', '').replace(')', '')
        })
          .then(() => {
            user.id && fetchWardrobe(user.id)

            onEnqueueSnackbar(t('success.categoryUpdated'), 'success')
          })
          .catch((e: any) => {
            console.log(e)
            onEnqueueSnackbar(t('error.unableToUpdateDetails'), 'error')
          })
      }
    },
    [fetchWardrobe, user, onEnqueueSnackbar, t]
  )

  const writeProduct = useCallback(
    async (
      file: any,
      title: string,
      description: string,
      price: string,
      category: string,
      link: string,
      callback?: () => void
    ) => {
      if (user) {
        setIsDataLoading(true)

        createImage({
          file,
          callback(img) {
            if (user?.id) {
              const id = uuidv4()
              const docRef = doc(db, 'products', id)

              setDoc(docRef, {
                src: img.src,
                title,
                id,
                description,
                price,
                category,
                link
              }).then(() => {
                onEnqueueSnackbar(t('success.itemUpdated'), 'success')
                callback && callback()
                setIsDataLoading(false)
              })
            }
          }
        })
      }
    },
    [onEnqueueSnackbar, t, createImage, user, setIsDataLoading]
  )

  const writeMessage = useCallback(
    async (
      name: string,
      email: string,
      message: string,
      callback?: () => void
    ) => {
      setIsDataLoading(true)

      const id = uuidv4()
      const docRef = doc(db, 'contacts', id)

      setDoc(docRef, {
        id,
        name,
        email,
        message,
        created: new Date(),
        isOpened: false
      }).then(() => {
        onEnqueueSnackbar(t('success.itemUpdated'), 'success')
        setIsDataLoading(false)
        callback && callback()
      })
    },
    [onEnqueueSnackbar, t, setIsDataLoading]
  )

  useEffect(() => {
    fetchFeatures()
  }, [fetchFeatures])

  return (
    <DataContext.Provider
      value={{
        // User...
        writeUser,
        fetchUser,
        deleteUser,
        clearUser,
        writeAvatar,
        updateName,
        updateEmail,

        // Products...
        writeCategory,
        writeProduct,
        fetchProducts,
        fetchCategories,

        // Contact...
        writeMessage,
        fetchMessages,

        // Features
        fetchFeatures,
        features,

        user,
        db,
        categories,
        products,
        messages
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export const DataConsumer = DataContext.Consumer
