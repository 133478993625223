import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

interface Props {
  tag: string
}

export const HTMLTitle = (props: Props) => {
  const { tag } = props
  const { t, i18n } = useTranslation()

  return (
    <Helmet>
      <title>{`${
        i18n.exists(tag)
          ? tag === 'shared.title'
            ? t(tag)
            : `${t(tag)} - ${t('shared.title')}`
          : t('title')
      }`}</title>
    </Helmet>
  )
}
