import { css, styled } from '@mui/system'
import { animated, useTrail } from '@react-spring/web'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import { ThemeColorType } from 'types'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  isRightAligned?: boolean
  title: string
  colorType?: ThemeColorType
  isSmall?: boolean
  isInverted?: boolean
}

export const Wrapper = styled('span')<{ isRightAligned: boolean }>(
  ({ isRightAligned }) => css`
    position: relative;
    display: flex;

    ${isRightAligned &&
    css`
      justify-content: right;
      text-align: right;
    `}
  `
)

const AnimatedText = styled(animated.span, {
  shouldForwardProp: prop =>
    prop !== 'isSmall' && prop !== 'colorType' && prop !== 'isInverted'
})<{ isSmall?: boolean; isInverted?: boolean; colorType: ThemeColorType }>(
  ({
    colorType,
    isSmall = false,
    isInverted = false,
    theme: {
      palette: { primary, secondary }
    }
  }) => css`
    position: relative;
    display: block;
    line-height: ${isSmall ? '2rem' : '3rem'};
    font-size: ${isSmall ? '2rem' : '3rem'};
    font-weight: 800;
    letter-spacing: 0;
    will-change: transform, opacity;
    word-break: break-all;
    color: ${colorType === 'primary' ? primary.main : secondary.main};

    ${isInverted &&
    css`
      color: #fff;
      background: ${colorType === 'primary' ? primary.main : secondary.main};
    `}
  `
)

const TitleWrapper = styled('span')`
  display: flex;
  flex-direction: column;
`

const AnimatedTitleWrapper: React.FC<{
  open: boolean
  isSmall: boolean
  isRightAligned: boolean
  children: JSX.Element[]
  colorType: ThemeColorType
  isInverted?: boolean
}> = props => {
  const { open, children, colorType, isSmall, isInverted } = props

  const items = React.Children.toArray(children)

  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    from: { opacity: 0, x: 20 }
  })

  return (
    <TitleWrapper>
      {trail.map(({ ...style }, index) => (
        <AnimatedText
          key={index}
          style={style}
          colorType={colorType}
          isSmall={isSmall}
          isInverted={isInverted}
        >
          <animated.div>{items[index]}</animated.div>
        </AnimatedText>
      ))}
    </TitleWrapper>
  )
}

export const AnimatedTitle = (props: Props) => {
  const {
    isRightAligned = false,
    title,
    colorType = 'primary',
    isSmall = false,
    isInverted = false
  } = props

  const { ref, inView } = useInView({
    threshold: 1
  })

  return (
    <Wrapper ref={ref} isRightAligned={isRightAligned}>
      <AnimatedTitleWrapper
        open={inView}
        isRightAligned={isRightAligned}
        colorType={colorType}
        isSmall={isSmall}
        isInverted={isInverted}
      >
        {title.split('%').map(line => (
          <span key={`animated-title-${uuidv4()}`}>{line}</span>
        ))}
      </AnimatedTitleWrapper>
    </Wrapper>
  )
}
