import { ThemeProvider } from '@mui/material/styles'
import { App, Loader } from 'components'
import {
  AuthProvider,
  DataProvider,
  LoadingProvider,
  SnackbarProvider,
  StorageProvider
} from 'contexts'
import { SnackbarProvider as SnackbarProviderNotistack } from 'notistack'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax'
import { nk } from 'themes'

import 'assets/index.css'
import 'utils/locale'

import 'react-image-gallery/styles/css/image-gallery.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <SnackbarProviderNotistack>
          <SnackbarProvider>
            <ParallaxProvider>
              <HelmetProvider>
                <LoadingProvider>
                  <StorageProvider>
                    <DataProvider>
                      <ThemeProvider theme={nk}>
                        <AuthProvider>
                          <App />
                          <div id='modal' />
                        </AuthProvider>
                      </ThemeProvider>
                    </DataProvider>
                  </StorageProvider>
                </LoadingProvider>
              </HelmetProvider>
            </ParallaxProvider>
          </SnackbarProvider>
        </SnackbarProviderNotistack>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
)
