import { Checkbox as CheckboxComponent } from '@mui/material'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

interface Props {
  onChange: () => void
  checked: boolean
}

export const Checkbox = (props: Props) => {
  const { onChange, checked } = props

  return (
    <CheckboxComponent
      onChange={onChange}
      checked={checked}
      icon={<RadioButtonUncheckedIcon />}
      checkedIcon={<RadioButtonCheckedIcon />}
    />
  )
}
