import { css, styled } from '@mui/system'
import { WHLogo } from 'components'
import { pulseFadeAnimation } from 'utils'

interface Props {
  isFullpage?: boolean
  title?: string
}

const Wrapper = styled('div')<{ isFullpage?: boolean }>(
  ({ isFullpage = true }) => css`
    position: ${isFullpage ? 'fixed' : 'absolute'};
    background: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
  `
)

const LogoWrapper = styled(`div`)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 150px;
  animation: ${pulseFadeAnimation} 0.4s ease-out infinite;
  z-index: 20;
`

export const Loader = (props: Props) => {
  const { isFullpage = true } = props

  return (
    <Wrapper isFullpage={isFullpage}>
      <LogoWrapper>
        <WHLogo />
      </LogoWrapper>
    </Wrapper>
  )
}
