import { AnimatedTitle } from 'components'
import { styled, css } from '@mui/system'

const Wrapper = styled('div')<{ src: string }>(
  ({
    theme: {
      shape: { borderRadius }
    },
    src
  }) => css`
    position: relative;
    background-image: url(${src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 400px;
    border-radius: 40px;
    margin: 1rem;
    border-radius: ${borderRadius}rem;
    overflow: hidden;
  `
)

const ContentWrapper = styled('div')`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(20%, -50%);
`
const SubtitleWrapper = styled('div')`
  margin-top: 1rem;
`

interface Props {
  title: string
  subtitle?: string
  src: string
}

export const CarouselBannerItem = ({ title, subtitle, src }: Props) => {
  return (
    <Wrapper src={src}>
      <ContentWrapper>
        <AnimatedTitle title={title} isInverted />
        {subtitle && (
          <SubtitleWrapper>
            <AnimatedTitle title={subtitle} isSmall={true} />
          </SubtitleWrapper>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}
