import CloseIcon from '@mui/icons-material/Close'
import { css, styled } from '@mui/system'
import { animated, useSpring } from '@react-spring/web'
import { useViewportSize, useWindowSize } from 'hooks'
import { Helmet } from 'react-helmet-async'

interface Props {
  show: boolean
  onClose: () => void
  children: JSX.Element
}

const Wrapper = styled(animated.div, {
  shouldForwardProp: prop => prop !== 'isFullWidth'
})<{ isFullWidth: boolean }>(
  ({ isFullWidth }) => css`
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    width: ${isFullWidth ? '100%' : '50%'};
    z-index: 11;
    opacity: 0;
    padding: 2rem;
    overflow-y: scroll;
    background: #fff;
    filter: drop-shadow(0 0.5rem 0.5rem rgb(0 0 0 / 0.4));
  `
)

const Overlay = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 10;
  opacity: 0.6;
  backdrop-filter: blur(5px);
  cursor: pointer;
`

const CloseWrapper = styled('button')(
  ({
    theme: {
      palette: { primary }
    }
  }) => css`
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: #fff;
    margin: 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    color: ${primary.main};
  `
)

export const Drawer = (props: Props) => {
  const { show, onClose, children } = props

  const { windowWidth } = useWindowSize()
  const { isXS, isS } = useViewportSize()

  const styles = useSpring({
    right: show ? 0 : windowWidth !== undefined ? -windowWidth : 0,
    opacity: show ? 1 : 0
  })

  return (
    <>
      <Helmet
        style={[
          {
            cssText:
              show &&
              `
            body {
              height: 100vh;
              overflow-y: hidden;
            }
        `
          }
        ]}
      />
      {show && <Overlay onClick={onClose} />}
      <Wrapper style={styles} isFullWidth={isXS || isS}>
        <CloseWrapper onClick={onClose}>
          <CloseIcon fontSize='medium' />
        </CloseWrapper>
        {children}
      </Wrapper>
    </>
  )
}
