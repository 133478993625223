import { useContext, useEffect } from 'react'
import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ResetPassword as ResetPasswordForm } from 'forms'
import { AuthContext } from 'contexts'
import { MarginWrapper, AnimatedTitle, HTMLTitle } from 'components'
import { getParameterByName } from 'utils'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
`

export const ResetPassword = () => {
  const { resetPassword, authUser, loginError, clearError } =
    useContext(AuthContext)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleRegisterButton = () => navigate('/', { replace: true })

  const actionCode = getParameterByName('oobCode')

  useEffect(() => {
    authUser && navigate('/', { replace: true })
  }, [authUser, navigate])

  const handleResetPassword = (password: string) =>
    resetPassword(actionCode, password)

  return (
    <>
      <HTMLTitle tag='resetPassword.title' />
      <MarginWrapper>
        <Wrapper>
          <div>
            <AnimatedTitle title={t('resetPassword.title')} />
            <Typography variant='body2'>
              {t('resetPassword.description')}
            </Typography>
            <ResetPasswordForm
              onSubmit={handleResetPassword}
              onClearError={clearError}
              error={loginError}
            />
          </div>
          <ButtonWrapper>
            <Button onClick={handleRegisterButton}>{t('shared.login')}</Button>
          </ButtonWrapper>
        </Wrapper>
      </MarginWrapper>
    </>
  )
}
