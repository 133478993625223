import { yupResolver } from '@hookform/resolvers/yup'
import { TextareaAutosize } from '@mui/base'
import { Button, FormLabel, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import { ErrorMessage } from 'components'
import { MuiColorInput } from 'mui-color-input'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

interface Props {
  onSubmit: (data: CategoryProps) => void
  error: string | null
  onClearError: () => void
}

type CategoryProps = {
  title: string
  description: string
  color: string
}

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: end;
  margin-top: 0.5rem;
`

const StyledTextField = styled(TextField)`
  width: 100%;
`

const StyledTextArea = styled(TextareaAutosize)`
  resize: none;
  border: none;
  outline: none;
  margin: 1rem 0;
  text-transform: uppercase;
  width: 100%;

  ::placeholder {
    text-transform: initial;
  }
`

const StyledMuiColorInput = styled(MuiColorInput)`
  padding: 0;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiInputBase-formControl {
    padding: 0;
  }
`

const schema = yup
  .object()
  .shape({
    title: yup.string().required('Required')
  })
  .required()

export const Category = (props: Props) => {
  const { onSubmit, error, onClearError } = props

  const muiTheme = useTheme()
  const { t } = useTranslation()

  const { handleSubmit, control } = useForm<CategoryProps>({
    resolver: yupResolver(schema)
  })

  const handleFormSubmit = (data: CategoryProps) => {
    onClearError()
    onSubmit(data)
  }

  return (
    <StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
      {error && <ErrorMessage theme={muiTheme}>{error}</ErrorMessage>}
      <Controller
        defaultValue=''
        name='title'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <StyledTextField
            onChange={onChange}
            value={value}
            label={t('shared.titlePlaceholder')}
            error={!!error}
            helperText={error && error.message}
            required
            variant='standard'
          />
        )}
      />
      <Controller
        name='description'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <FormLabel>Description</FormLabel>

            <StyledTextArea
              placeholder='Enter a description...'
              value={value}
              onChange={onChange}
              minRows={4}
            />
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </>
        )}
      />
      <Controller
        name='color'
        control={control}
        render={({ field, fieldState }) => (
          <StyledMuiColorInput {...field} format='rgb' />
        )}
      />
      <ButtonWrapper>
        <Button type='submit' variant='contained'>
          {t('shared.save')}
        </Button>
      </ButtonWrapper>
    </StyledForm>
  )
}
