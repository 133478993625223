import { styled } from '@mui/system'
import { TextField, Button, FormControlLabel, Typography } from '@mui/material'
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isValidNumber } from 'libphonenumber-js'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { ErrorMessage, DateOfBirth, Checkbox } from 'components'
import { Register as RegisterProps } from 'types'

import './index.css'

interface Props {
  onSubmit: (props: RegisterProps) => void
  error: string | null
  onClearError: () => void
}

type RegistrationFormProps = {
  forename: string
  surname: string
  email: string
  tel: string
  dob: number
  password: string
  confirmPassword: string
  terms: boolean
}

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled('div')`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
`
const StyledTypography = styled(Typography)`
  opacity: 0.6;
  margin-top: 0.5rem;
`

const schema = yup
  .object()
  .shape({
    forename: yup.string().required('Required'),
    surname: yup.string().required('Required'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Required'),
    password: yup
      .string()
      .required('Required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must contain 8 characters and a special character.'
      ),
    confirmPassword: yup
      .string()
      .required('Required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    tel: yup
      .string()
      .test('tel', 'Please enter a valid phone number', value => {
        return value ? isValidNumber(value) : false
      }),
    dob: yup
      .number()
      .test('dob', 'Please enter a valid date of birth', value => {
        return moment(value).isValid()
      }),
    terms: yup.bool().oneOf([true], 'You must accept the terms and conditions')
  })
  .required()

export const Register = (props: Props) => {
  const { onSubmit, error } = props

  const { t } = useTranslation()

  const { handleSubmit, control } = useForm<RegistrationFormProps>({
    resolver: yupResolver(schema)
  })

  const onFormSubmit = (data: RegistrationFormProps) =>
    onSubmit({
      email: data.email,
      password: data.password,
      forename: data.forename,
      surname: data.surname,
      tel: data.tel,
      dob: data.dob
    })

  return (
    <StyledForm onSubmit={handleSubmit(onFormSubmit)}>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Controller
        name='forename'
        defaultValue=''
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            value={value}
            label={t('shared.forename')}
            error={!!error}
            helperText={error && error.message}
            variant='standard'
            required
          />
        )}
      />
      <Controller
        name='surname'
        defaultValue=''
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            value={value}
            label={t('shared.surname')}
            error={!!error}
            helperText={error && error.message}
            variant='standard'
            required
          />
        )}
      />
      <Controller
        name='email'
        defaultValue=''
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            value={value}
            label={t('shared.email')}
            error={!!error}
            helperText={error && error.message}
            variant='standard'
            required
          />
        )}
      />
      <Controller
        name='tel'
        control={control}
        rules={{ validate: matchIsValidTel }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <MuiTelInput
            defaultCountry='GB'
            value={value}
            label={t('shared.tel')}
            onChange={onChange}
            variant='standard'
            forceCallingCode
            error={!!error}
            helperText={error && error.message}
          />
        )}
      />
      <StyledTypography>{t('shared.dob')}</StyledTypography>
      <Controller
        name='dob'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <DateOfBirth onChange={onChange} date={value} />
            {error && (
              <ErrorMessage isSmall>
                {t('error.invalidDateOfBirth')}
              </ErrorMessage>
            )}
          </>
        )}
      />
      <Controller
        name='password'
        defaultValue=''
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            value={value}
            label={t('shared.password')}
            type='password'
            error={!!error}
            helperText={error && error.message}
            variant='standard'
            required
          />
        )}
      />
      <Controller
        name='confirmPassword'
        defaultValue=''
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            value={value}
            label={t('shared.confirmPassword')}
            type='password'
            error={!!error}
            helperText={error && error.message}
            variant='standard'
            required
          />
        )}
      />
      <Controller
        name='terms'
        defaultValue={false}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <FormControlLabel
              control={<Checkbox onChange={onChange} checked={value} />}
              label={t('shared.agreeTerms')}
            />
            {error && <ErrorMessage isSmall>{error.message}</ErrorMessage>}
          </>
        )}
      />
      <ButtonWrapper>
        <Button
          type='submit'
          variant='contained'
          startIcon={<AppRegistrationOutlinedIcon />}
        >
          {t('shared.register')}
        </Button>
      </ButtonWrapper>
    </StyledForm>
  )
}
