import { Container, styled } from '@mui/system'
import { Parallax } from 'components'

interface Props {
  backgroundSrc: string
  children: JSX.Element
  height?: number
}

const ContentWrapper = styled('div')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
`

export const ParallaxSlice = (props: Props) => {
  const { children, backgroundSrc, height } = props

  return (
    <Parallax
      height={height}
      backgroundSrc={backgroundSrc}
      foregroundComponent={
        <ContentWrapper>
          <Container>{children}</Container>
        </ContentWrapper>
      }
    />
  )
}
