import { Typography } from '@mui/material'
import { css, styled } from '@mui/system'
import { animated } from '@react-spring/web'

interface Props {
  copy: string
  isFull?: boolean
}

const Wrapper = styled(animated.div, {
  shouldForwardProp: prop => prop !== 'isFull'
})<{ isFull: boolean }>(
  ({ isFull }) => css`
    max-width: ${isFull ? 'initial' : '400px'};
  `
)
const StyledTypography = styled(Typography)`
  margin: 10px 0;
`

export const CopyTile = (props: Props) => {
  const { copy, isFull = false } = props

  return (
    <Wrapper isFull={isFull}>
      <StyledTypography variant='body2' color='text.secondary'>
        {copy}
      </StyledTypography>
    </Wrapper>
  )
}
