import { styled } from '@mui/system'
import { WHLogo } from 'components'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LogoWrapper = styled('div')`
  height: 10rem;
  width: 10rem;
  position: relative;
`
const CopyWrapper = styled('div')`
  flex: 1;
  font-size: 4rem;
  color: #333;
  text-align: center;
  position: relative;
  padding: 0 1rem;
  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
`

export const Banner = () => {
  return (
    <Wrapper>
      <LogoWrapper>
        <WHLogo />
      </LogoWrapper>
      <CopyWrapper>WOODEN MALLET</CopyWrapper>
    </Wrapper>
  )
}
