import { styled } from '@mui/system'

export const DotOverlay = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: radial-gradient(black 25%, transparent 25%);
  background-size: 0.25rem 0.25rem;
  opacity: 0.2;
`
