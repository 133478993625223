import { Container } from '@mui/material'
import { css, styled } from '@mui/system'
import {
  Add,
  AnimatedTitle,
  Banner,
  ComingSoon,
  CopyTile,
  Copyright,
  Drawer,
  EtsyLink,
  Header,
  ParallaxSlice,
  ShowTile,
  SwipeList
} from 'components'
import { DataContext } from 'contexts'
import { useCurrentPath, useViewportSize } from 'hooks'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'
import { hyphenateString } from 'utils'

const CategoryContentWrapper = styled('div')<{ isColumn: boolean }>(
  ({ isColumn }) => css`
    display: flex;
    flex-direction: ${isColumn ? 'column' : 'row'};
    margin-top: 1rem;
  `
)

const Slice = styled('div')<{ color: string }>(
  ({ color }) => css`
    padding-top: 3rem;
    padding-bottom: 3rem;
    background: rgb(${color});
  `
)

const HomeCopyWrapper = styled('div')`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

export const Home = () => {
  const { products, categories, fetchProducts, fetchCategories, user } =
    useContext(DataContext)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isXS, isS } = useViewportSize()
  const currentPath = useCurrentPath()

  const handleAddItemClick = () => navigate(`/edit`)

  useEffect(() => {
    fetchProducts()
    fetchCategories()
  }, [fetchProducts, fetchCategories])

  const isHome = currentPath === '/'

  return (
    <>
      <Header />
      <ComingSoon />
      <ParallaxSlice
        height={500}
        backgroundSrc='https://firebasestorage.googleapis.com/v0/b/nk-resource.appspot.com/o/bg.jpeg?alt=media&token=6f8e93fe-b4f5-40c4-a824-ee4395236897'
      >
        <Banner />
      </ParallaxSlice>
      <Container>
        <HomeCopyWrapper>{t('intro')}</HomeCopyWrapper>
      </Container>
      <>
        {categories?.map(category => {
          return (
            <Slice
              key={`slice-${hyphenateString(category.title)}`}
              id={`slice-${hyphenateString(category.title)}`}
              color={category.color ?? '255,255,255'}
            >
              <Container>
                <AnimatedTitle title={category.title} />
                <CategoryContentWrapper isColumn={isS || isXS}>
                  {category.description && (
                    <CopyTile
                      isFull={isS || isXS}
                      copy={category.description}
                    />
                  )}
                  <SwipeList color={category.color ?? '255,255,255'}>
                    <>
                      {products &&
                        products.map(
                          product =>
                            product.category === category.title && (
                              <ShowTile
                                key={`show-tile-${category.title}-${product.title}`}
                                title={product.title}
                                img={{ src: product.src }}
                                onClick={() =>
                                  navigate(`product/${product.id}`)
                                }
                              />
                            )
                        )}
                    </>
                  </SwipeList>
                </CategoryContentWrapper>
              </Container>
            </Slice>
          )
        })}
      </>
      <ParallaxSlice backgroundSrc='https://firebasestorage.googleapis.com/v0/b/nk-resource.appspot.com/o/bg.jpeg?alt=media&token=6f8e93fe-b4f5-40c4-a824-ee4395236897'>
        <Container>
          {t('etsy')}
          <EtsyLink />
        </Container>
      </ParallaxSlice>
      <Copyright />
      {user?.adminLevel === 1 && <Add onClick={handleAddItemClick} />}
      <Drawer show={!isHome} onClose={() => navigate(`/`)}>
        <Outlet />
      </Drawer>
    </>
  )
}
